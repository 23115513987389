<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      :disabled="disabled"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="dateFormatted"
          :label="label"
          :disabled="disabled"
          readonly
          dense
          outlined
          hide-details
          class="c-input-small"
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable locale="vi-vn" :min="minDate" :disabled="disabled">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePicker",
  props: {
    name: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    minDate: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    defaultDate: {
      type: [Array, String],
      default:() => null
    }
  },
  data: () => ({
    date: moment().format('YYYY-MM-DD'),
    dateFormatted: moment().format('DD/MM/YYYY'),
    menu: false,
  }),
  watch: {
    date () {
      this.dateFormatted = moment(this.date).format('DD/MM/YYYY')
      this.onDateChange()
    },
  },
  methods: {
    onDateChange() {
      this.$emit('change', this.date)
    }
  },
  mounted() {
    this.onDateChange()
  }
}
</script>

<style scoped>

</style>